<template>
    <div class="page">

        <Head />
        <Sidebar />
        <div class="body-main">
            <breadInfo />
            <div class="container bg flex-1 flex-v">
                <div class="pub-tt m15">
                    <h5 class="bt">
                        <img src="../assets/images/equip_01.png" class="ico" alt="" />能耗分类报表
                    </h5>
                    <div class="dix"></div>
                </div>
                <div class="p025 top-bar">
                    <el-form ref="form" class="top-form" :model="form">
                        <el-row>
                            <el-col :span="24" class="flex flex-align-start">
                                <el-form-item>
                                    <el-date-picker v-model="form.date1" type="date" value-format="yyyy-MM-dd"
                                        placeholder="选择日期" :picker-options="pickerStartOptions(form.date2)"
                                        @change="timeOptions">
                                    </el-date-picker>
                                    <span class="dateline">~</span>
                                    <el-date-picker v-model="form.date2" type="date" value-format="yyyy-MM-dd"
                                        placeholder="选择日期" @focus="timeOptionsEnd" :picker-options="pickerEndOptions"
                                        ref="lastDiscoveryEndTime">
                                    </el-date-picker>
                                </el-form-item>
                                <button class="ann annbg1 submit" @click.prevent="getList">
                                    提交
                                </button>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="6">
                                <button class="ann annbg2" @click.prevent="exportInfo">
                                    导出
                                </button>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="flex-1 flex-v p025">
                    <div class="table flex-1">
                        <el-table :data="tableData" height="100%" style="width: 100%" v-loading="loading"
                            element-loading-background="rgba(0, 0, 0, 0.8)">
                            <el-table-column type="index" width="60" label="序号">
                            </el-table-column>
                            <el-table-column prop="deviceName" label="设备名">
                            </el-table-column>
                            <el-table-column prop="categoryName" label="规格种类">
                            </el-table-column>
                            <el-table-column prop="unit" label="软度单位"> </el-table-column>
                            <el-table-column v-for="(item, key) in timeList" :label="item.time" :key="key">
                                <template>{{ item.num }}</template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination @current-change="handleCurrentChange" v-model:current-page="pageInfo.pageNum"
                        layout="prev, pager, next, jumper,total" :total="total" :page-size="pageInfo.pageSize">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import { energyList, exportExcel } from "@/api/analysis";
export default {
    data() {
        return {
            form: {
                date1: "",
                date2: "",
            },
            pickerStartOptions(endTime) {
                return {
                    disabledDate(time) {
                        if (endTime) {
                            // 拼接 00:00:00 是为了统一日期从0点开始
                            return time.getTime() > new Date(endTime + " 00:00:00").getTime();
                        }
                    },
                };
            },
            pickerEndOptions: {},
            timeList: [],
            tableData: [],
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
            },
            loading: false,
            total: 0,
        };
    },
    components: {
        Head,
        Sidebar,
        breadInfo,
    },
    methods: {
        // 当开始时间改变时 就让结束时间清空
        timeOptions() {
            this.form.date2 = "";
        },
        timeOptionsEnd() {
            // 若不填开始时间的值，先选择结束时间就提示用户填写开始时间
            if (this.form.date1 == "" || this.form.date1 == null) {
                // 不让弹出结束时间框
                this.$refs.lastDiscoveryEndTime.pickerVisible = false;
                return this.$message.warning("请填写开始时间");
            } else {
                // 若开始时间填写 结束时间的弹出选择时间框正常显示
                this.$refs.lastDiscoveryEndTime.pickerVisible = true;
            }
            let that = this;
            // 结束时间是在开始时间的基础上去建立可选范围
            this.pickerEndOptions = {
                disabledDate(time) {
                    let dataLast = new Date(that.form.date1).getTime();
                    return (
                        // 不超过一个月且不能选当天且可以选和开始时间一样的日期
                        time.getTime() > dataLast + 60 * 24 * 60 * 60 * 1000
                    );
                },
            };
        },
        /**
         * 分页操作
         * @param {*} val
         */
        handleCurrentChange(val) {
            this.pageInfo.pageNum = val;
        },
        /**
         * 获取列表
         */
        getList() {
            this.loading = true;
            let param = {
                projectId: Number(sessionStorage.getItem("projectId")),
                startTime: this.form.date1,
                endTime: this.form.date2,
            };
            let params = Object.assign(this.pageInfo, param, {});
            energyList(params).then((res) => {
                if (res.data.code == 0) {
                    console.log(res);
                    this.tableData = res.data.rows;
                    this.timeList = res.data.rows[0].dataList;
                    this.total = res.data.total;
                }
                this.loading = false;
            });
        },
        /**
         * 导出
         */
        exportInfo() {
            let param = {
                endTime: this.form.date2,
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize,
                projectId: Number(sessionStorage.getItem("projectId")),
                startTime: this.form.date1,
            };
            this.download(
                "apis/nengYuanFenXi/002-nengYuanFenXiReport",
                {
                    ...param,
                },
                `sheet_${new Date().getTime()}.xlsx`
            );
        },
    },
};
</script>

<style scoped>
.dateline {
    margin: 0 20px;
    font-size: 18px;
    color: #ccd9e5;
}

.top-form .submit {
    margin-left: 15px;
}
</style>
